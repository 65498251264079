<template>
  <v-card class="custom-field-popup-card">
    <v-card-title class="px-5 pt-4 pb-4">
      <div> {{ `${leadSegment && leadSegment.id ? 'Edit' : 'Create'} Lead Score Segment` }}</div>
    </v-card-title>
    <v-divider />
    <v-card-text class="px-6 pt-6 pb-10">
      <validation-observer ref="textInput">
        <v-row class="pa-0 mt-7">
          <v-col cols="12" class="py-0 mb-3 pr-2">
            <CustomTextInput
              v-model="temp_segment.name"
              header="Name"
              class="mt--2"
              required
            />
          </v-col>
          <v-col cols="6" class="py-0 pl-2">
            <CustomTextInput
              v-model="temp_segment.minimumScore"
              type="number"
              header="Minimum Score"
              hint="Leave empty for no minimum"
              class="mt--2"
            />
          </v-col>
          <v-col cols="6" class="py-0 pl-2">
            <CustomTextInput
              v-model="temp_segment.maximumScore"
              type="number"
              header="Maximum Score"
              hint="Leave empty for no maximum"
              class="mt--2"
            />
          </v-col>
          <v-col cols="6" class="py-0 pl-2">
            <CustomDropdown
              v-model="segmentSelection"
              header="Segment"
              :items="segments"
              class="mt--2"
              hint="This segment's criteria will be changed to represent this lead score segment's range"
            />
          </v-col>
          <v-col cols="12" class="py-0 mt-3 pl-2">
            <div>
              Note: The min/max scores selected are inclusive, so the segments include contacts who have a lead score that is equal to the number selected.
            </div>
          </v-col>
        </v-row>
      </validation-observer>
    </v-card-text>

    <v-card-actions
      class="divider-with-shadow d-flex justify-end px-6 pb-3 pt-3"
    >
      <div
        class="simple-text-button button--gray mx-8 d-flex align-center"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>

      <v-btn
        v-if="leadSegment && leadSegment.id"
        height="34px"
        class="custom-button custom-button--blue"
        width="150px"
        @click="edit()"
      >
        Save
      </v-btn>
      <v-btn
        v-else
        height="34px"
        class="custom-button custom-button--blue"
        width="150px"
        @click="create()"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";

export default {
  name: "CreateLeadScoreSegmentDialog",
  components: { CustomTextInput, CustomDropdown },
  props: {
    leadSegment: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      segments: [
        { value: null, label: 'No Segment' },
      ],
      temp_segment: {
        name: null,
        minimumScore: null,
        maximumScore: null,
        segment: null,
      },
      segmentSelection: null,
    };
  },
  async created() {
    await this.loadSegment();

    if (this.leadSegment?.id) {
      this.temp_segment = {...this.temp_segment, ...this.leadSegment};
      this.segments.push({
        value: this.leadSegment.segment.id,
        label: this.leadSegment.segment.name,
      });
      this.segmentSelection = this.leadSegment.segment.id;
    }
  },
  methods: {
    async loadSegment() {
      const response = await this.$rest.segment.get_segment_for_leadscore();

      if (!response.data) {
        return;
      }

      response.data.forEach((item) => {
        this.segments.push({
          value: item.id,
          label: item.name,
        });
      });
    },
    async create() {
      if (!await this.$refs.textInput.validate()) {
        return;
      }

      let segment = {
        ...this.leadSegment,
        name: this.temp_segment.name,
        minimumScore: this.temp_segment.minimumScore ? parseInt(this.temp_segment.minimumScore) : null,
        maximumScore: this.temp_segment.maximumScore ? parseInt(this.temp_segment.maximumScore) : null,
        segment: this.segmentSelection,
      };
      this.$emit("create", segment);
      this.$emit("dismiss");
    },
    async edit() {
      if (!await this.$refs.textInput.validate()) {
        return;
      }

      let segment = {
        ...this.leadSegment,
        name: this.temp_segment.name,
        minimumScore: this.temp_segment.minimumScore ? parseInt(this.temp_segment.minimumScore) : null,
        maximumScore: this.temp_segment.maximumScore ? parseInt(this.temp_segment.maximumScore) : null,
        segment: this.segmentSelection,
      };

      this.$emit("edit", segment);
      this.$emit("dismiss");
    },
  },
};
</script>

<style lang="scss" scoped>
.mt--2 {
  margin-top: -8px;
}
.comment-text {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 1.77;
  color: #66788e;
}
</style>
