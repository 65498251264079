<template>
  <v-card class="custom-field-popup-card">
    <v-card-title class="px-5 pt-4 pb-4">
      <div> {{ `${rule && rule.id ? 'Edit' : 'Create'} Lead Score Rule` }}</div>
    </v-card-title>
    <v-divider />
    <v-card-text class="px-6 pt-6 pb-10">
      <validation-observer ref="textInput">
        <CustomDropdown
          v-model="tempRule.actionCode"
          placeholder="Select an action code"
          class="mt--2"
          header="Action Code"
          :items="actionCodes"
          item-value="dropdownId"
          item-text="fullName"
          autocomplete
          return-object
          required
        />

        <CustomDropdown
          v-if="!isPhoneActionSelected"
          v-model="tempRule.creativeId"
          placeholder="Select creative types"
          class="mt-7"
          :items="actualCreatives"
          header="Creatives"
          :required="!isPhoneActionSelected"
        />

        <v-row class="pa-0 mt-7">
          <v-col cols="6" class="py-0 pr-2">
            <CustomTextInput
              v-model="tempRule.score"
              header="Score"
              type="number"
              class="mt--2"
              required
            />
          </v-col>
          <v-col cols="6" class="py-0 pl-2">
            <CustomTextInput
              v-model="tempRule.numActionsToScore"
              type="number"
              header="Actions to Score"
              class="mt--2"
              required
            />
          </v-col>
        </v-row>

        <v-row class="pa-0 mt-7">
          <v-col cols="8" class="py-0 pr-2">
            <CustomTextInput
              v-model="tempRule.numberOfUnits"
              type="number"
              header="Score Time Frame"
              required
            />
          </v-col>
          <v-col cols="4" class="py-0 pl-2">
            <CustomDropdown
              v-model="tempRule.unitOfTime"
              :items="unitOfTimes"
              header="Unit"
              item-value="value"
              item-text="label"
              required
            />
          </v-col>
        </v-row>
      </validation-observer>
      <div class="comment-text mt-1" style="margin-top: 20px !important;">
        This limits the amount of actions to score within the time frame
        provided. If the value is 0, then it will only score the amount of
        actions to score.
      </div>
    </v-card-text>

    <v-card-actions
      class="divider-with-shadow d-flex justify-end px-6 pb-3 pt-3"
    >
      <div
        class="simple-text-button button--gray mx-8 d-flex align-center"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>

      <v-btn
        v-if="rule && rule.id"
        height="34px"
        class="custom-button custom-button--blue"
        width="150px"
        @click="edit()"
      >
        Save
      </v-btn>
      <v-btn
        v-else
        height="34px"
        class="custom-button custom-button--blue"
        width="150px"
        @click="create()"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";

export default {
  name: "CreateLeadScoreRuleDialog",
  components: { CustomDropdown, CustomTextInput },
  props: {
    rule: {
      default: () => {},
      type: Object,
    },
    bannerCreatives: {
      default: () => [],
      type: Array,
    },
    eventCreatives: {
      default: () => [],
      type: Array,
    },
    webCreatives: {
      default: () => [],
      type: Array,
    },
    emailCreatives: {
      default: () => [],
      type: Array,
    },
    directMailCreatives: {
      default: () => [],
      type: Array,
    },
    alertCreatives: {
      default: () => [],
      type: Array,
    },
    formCreatives: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      tempRule: {
        actionCode: null,
        creativeId: null,
        score: null,
        numActionsToScore: null,
        scoreTimeFrame: null,
        unitOfTime: null,
      },
      actionCodes: [],
      creatives: [{ value: 0, label: "All Creatives" }],
      unitOfTimes: [
        {
          value: 'minute',
          label: 'minute(s)'
        },
        {
          value: 'hour',
          label: 'hour(s)'
        },
        {
          value: 'day',
          label: 'day(s)'
        },
        {
          value: 'week',
          label: 'week(s)'
        },
      ],
    };
  },
  computed: {
    selectedAction () {
      if (this.tempRule.actionCode) {
        return this.actionCodes.find(item => {
          return item.id == this.tempRule.actionCode.id
            && item.tacticId == this.tempRule.actionCode.tacticId;
        });
      }

      return null;
    },
    actualCreatives () {
      if (!this.selectedAction) {
        return this.creatives;
      }

      const selectedActionTactic = parseInt(this.selectedAction.tacticId);

      if (selectedActionTactic === 5) {
        return [
          this.creatives[0],
          ...this.bannerCreatives?.map(banner => ({
            value: banner.id,
            label: banner.name
          })) ?? []
        ];
      }

      if (selectedActionTactic === 8) {
        return [
          this.creatives[0],
          ...this.eventCreatives?.map(event => ({
            value: event.id,
            label: event.name
          })) ?? []
        ];
      }

      if (selectedActionTactic === 7) {
        return [
          this.creatives[0],
          ...this.webCreatives?.map(web => {
            let label = '';

            if (web.name) {
              label = web.name;

              if (web.url) {
                label += ' (' + web.url + ')';
              }
            } else if (web.url) {
              label = web.url;
            } else {
              label = 'Unknown';
            }

            return {
              value: web.id,
              label,
            };
          }) ?? []
        ];
      }

      if (selectedActionTactic === 1) {
        return [
          this.creatives[0],
          ...this.emailCreatives?.map(email => ({
            value: email.id,
            label: email.name
          })) ?? []
        ];
      }

      if (selectedActionTactic === 2) {
        return [
          this.creatives[0],
          ...this.directMailCreatives?.map(directMail => ({
            value: directMail.id,
            label: directMail.name
          })) ?? []
        ];
      }

      if (selectedActionTactic === 10) {
        return [
          this.creatives[0],
          ...this.formCreatives?.map(form => ({
            value: form.id,
            label: form.name
          })) ?? []
        ];
      }

      if (selectedActionTactic === 11) {
        return [
          this.creatives[0],
          ...this.alertCreatives?.map(alert => ({
            value: alert.id,
            label: alert.name
          })) ?? []
        ];
      }

      return this.creatives;
    },
    isPhoneActionSelected() {
      return this.selectedAction?.tacticId === 4;
    },
  },
  async created() {
    await this.getActionCodes();

    if (this.rule?.id) {
      this.tempRule = {...this.tempRule, ...this.rule};

      this.$set(this.tempRule, 'actionCode', this.actionCodes.find(i => {
        return i.tacticId == this.tempRule.tacticType && i.id == this.tempRule.actionCode
      }));
    }
  },
  methods: {
    async create() {
      if (!await this.$refs.textInput.validate()) {
        return;
      }

      if (this.isPhoneActionSelected) {
        this.tempRule.creativeId = 0;
      }

      let rule = {
        ...this.tempRule,
        numberOfUnits: parseInt(this.tempRule.numberOfUnits),
        unitOfTime: this.tempRule.unitOfTime,
        actionCode: (this.tempRule.actionCode.id).toString(),
        tacticType: (this.tempRule.actionCode.tacticId).toString(),
        score: +this.tempRule.score,
        numActionsToScore: +this.tempRule.numActionsToScore,
        creativeId: this.tempRule.creativeId,
      }
      this.$emit("create", rule);
      this.$emit("dismiss");
    },
    async getActionCodes() {
      this.actionCodes = (await this.$rest.rules.get_available_codes()).data.map(i => ({
        ...i,
        dropdownId: i.tacticName + String(i.id),
        fullName: i.tacticName + ' - ' + i.name,
      })).sort((a, b) => {
        return a.fullName.localeCompare(b.fullName);
      });
    },
    async edit() {
      if (!await this.$refs.textInput.validate()) {
        return;
      }

      let rule = {
        ...this.tempRule,
        numberOfUnits: parseInt(this.tempRule.numberOfUnits),
        score: parseInt(this.tempRule.score),
        unitOfTime: this.tempRule.unitOfTime,
        numActionsToScore: +this.tempRule.numActionsToScore,
        actionCode: (this.tempRule.actionCode.id).toString(),
        tacticType: (this.tempRule.actionCode.tacticId).toString(),
      };

      this.$emit("edit", rule);
      this.$emit("dismiss");
    },
  },
};
</script>

<style lang="scss" scoped>
.mt--2 {
  margin-top: -8px;
}
.comment-text {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 1.77;
  color: #66788e;
}
</style>
