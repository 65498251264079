<template>
  <v-container class="manager-user mt-5 px-10" fluid>
    <PageHeader
      header-text="Lead Score"
      regular-button-text="Add Rule"
      outlined-button-text="Add Lead Segment"
      class="mb-7"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      :outlined-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="addRule()"
      @outlined-button-click="addSegment"
    >
      <template #subheader>
        <div>View and edit your lead score rules and segments</div>
      </template>
    </PageHeader>

    <v-row class="d-flex align-center justify-space-between pb-2">
      <v-col
        v-if="totalRules > currentPageSize"
        class="d-flex flex-nowrap align-center justify-end flex-grow-1 pl-2"
      >
        <pagination-buttons
          :current-page-number="currentPageNumber"
          :current-page-size="currentPageSize"
          :count-total="totalRules"
          :page-sizes="[25, 50, 100]"
          @next-page="getAvailableItemsNextPage"
          @prev-page="get_available_items_prev_page"
          @change-page-size="changePageSize"
        />
      </v-col>
    </v-row>

    <h4 class="mb-3">
      Lead Segments
    </h4>
    <LeadScoreSegmentsTable
      :segments="segments"
      @edit="segment => editSegment(segment)"
      @delete="id => {deleteSegmentDialog = true; deleteSegmentId = id;}"
    />

    <h4 class="mt-5 mb-3">
      Lead Rules
    </h4>
    <LeadScoreRulesTable
      :rules="rules"
      @action="handleAction"
    />

    <v-dialog v-model="dialog" scrollable max-width="560px">
      <CreateLeadScoreRuleDialog
        v-if="dialog"
        :rule="currentRule"
        :banner-creatives="bannerCreatives"
        :direct-mail-creatives="directMailCreatives"
        :email-creatives="emailCreatives"
        :event-creatives="eventCreatives"
        :web-creatives="webCreatives"
        :alert-creatives="alertCreatives"
        :form-creatives="formCreatives"
        @dismiss="dialog = false"
        @create="(rule) => createRule(rule)"
        @edit="(rule) => saveEditedRule(rule)"
      />
    </v-dialog>
    <v-dialog v-model="segmentDialog" scrollable max-width="560px">
      <CreateLeadScoreSegmentDialog
        v-if="segmentDialog"
        :lead-segment="currentSegment"
        @dismiss="segmentDialog = false"
        @create="createSegment"
        @edit="saveEditedSegment"
      />
    </v-dialog>

    <v-dialog
      v-model="deleteRuleDialog"
      scrollable
      max-width="360px"
      @click:outside="deleteRuleId = null"
    >
      <DeleteConfirmationDialog
        v-if="deleteRuleDialog"
        header="Delete Lead Score Rule"
        subheader="Do you really want to delete the lead score rule?"
        action_button_name="Delete"
        @delete="deleteRule()"
        @dismiss="
          deleteRuleDialog = false;
          deleteRuleId = null;
        "
      />
    </v-dialog>

    <v-dialog
      v-model="deleteSegmentDialog"
      scrollable
      max-width="360px"
      @click:outside="deleteSegmentId = null"
    >
      <DeleteConfirmationDialog
        v-if="deleteSegmentDialog"
        header="Delete Lead Score Segment Rule"
        subheader="Do you really want to delete the lead score rule?"
        action_button_name="Delete"
        @delete="deleteSegment()"
        @dismiss="
          deleteSegmentDialog = false;
          deleteSegmentId = null;
        "
      />
    </v-dialog>

    <v-dialog
      v-model="reapplyRuleDialog"
      scrollable
      max-width="360px"
      @click:outside="reapplyRuleId = null"
    >
      <DeleteConfirmationDialog
        v-if="reapplyRuleDialog"
        header="Apply Rule to Past Actions"
        subheader="Do you really want to apply this lead score rule to past actions?"
        action_button_name="Apply"
        action_button_class="custom-button custom-button--blue"
        @delete="reapplyRuleToPastActions"
        @dismiss="
          reapplyRuleDialog = false;
          reapplyRuleId = null;
        "
      >
        <template #extra>
          <div
            v-if="leadScoreLookBackPeriod"
            class="mt-3"
          >
            This rule would be applied to all matching actions since {{ leadScoreLookBackPeriod }} months ago.<br>
            <br>
            Note: This process will take some time depending on the number of actions affected and may take up to
            an hour to update the affected contacts and their lead score.
          </div>
        </template>
      </DeleteConfirmationDialog>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CreateLeadScoreRuleDialog from "@/views/LeadScore/components/CreateLeadScoreRuleDialog";
import CreateLeadScoreSegmentDialog from "@/views/LeadScore/components/CreateLeadScoreSegmentDialog";
import PaginationButtons from "@/sharedComponents/pagination/PaginationButtons";
import LeadScoreRulesTable from "@/views/LeadScore/components/LeadScoreRulesTable";
import LeadScoreSegmentsTable from "@/views/LeadScore/components/LeadScoreSegmentsTable";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";

export default {
  name: "LeadScoreRulesView",
  metaInfo: {
    title: 'Lead Score Rules'
  },
  components: {
    PageHeader,
    CreateLeadScoreRuleDialog,
    CreateLeadScoreSegmentDialog,
    PaginationButtons,
    LeadScoreRulesTable,
    LeadScoreSegmentsTable,
    DeleteConfirmationDialog
  },
  data() {
    return {
      editedRule: {},
      editedSegment: {},
      currentPageNumber: 1,
      currentPageSize: 25,
      dialog: false,
      segmentDialog: false,
      rules: [],
      totalRules: null,
      segments: [],
      currentRule: null,
      currentSegment: null,
      alertCreatives: null,
      bannerCreatives: null,
      eventCreatives: null,
      formCreatives: null,
      webCreatives: null,
      emailCreatives: null,
      directMailCreatives: null,
      deleteRuleDialog: false,
      deleteRuleId: null,
      reapplyRuleDialog: false,
      reapplyRuleId: null,
      deleteSegmentDialog: false,
      deleteSegmentId: null,
    };
  },
  computed: {
    leadScoreLookBackPeriod() {
      return this.$store.getters['user/account']?.leadScoreLookBackPeriod;
    }
  },
  async created() {
    await this.loadCreatives();
    await this.getRules();
    await this.getSegments();
  },
  methods: {
    async createRule(rule) {
      await this.$rest.rules.post_resource(rule);
      await this.getRules();
    },
    async createSegment(segment) {
      await this.$rest.lead_segments.post_resource(segment);
      await this.getSegments();
    },
    addRule() {
      this.currentRule = {}
      this.dialog = true
    },
    handleAction(event) {
      const action = event.event.value;
      const item = event.item;

      if (action === 'edit') {
        this.editRule(item);
      } else if (action === 'delete') {
        this.deleteRuleDialog = true;
        this.deleteRuleId = item.id;
      } else if (action === 'reapply') {
        this.reapplyRuleDialog = true;
        this.reapplyRuleId = item.id;
      }
    },
    editRule(rule) {
      this.currentRule = {...rule}
      this.dialog = true
    },
    addSegment() {
      this.currentSegment = {}
      this.segmentDialog = true
    },
    editSegment(segment) {
      this.currentSegment = {...segment}
      this.segmentDialog = true
    },
    async saveEditedRule(rule) {
      await this.$rest.rules.put_resource(rule.id, rule)
      await this.getRules();
    },
    async saveEditedSegment(segment) {
      await this.$rest.lead_segments.put_resource(segment.id, segment)
      await this.getSegments();
    },
    async deleteRule() {
      if (!this.deleteRuleId) {
        return;
      }

      await this.$rest.rules.delete_resource(this.deleteRuleId)
      this.deleteRuleDialog = false;
      this.deleteRuleId = null;
      await this.getRules();
    },
    async deleteSegment() {
      if (!this.deleteSegmentId) {
        return;
      }

      await this.$rest.lead_segments.delete_resource(this.deleteSegmentId)
      this.deleteSegmentDialog = false;
      this.deleteSegmentId = null;
      await this.getSegments();
      await this.getRules();
    },
    async reapplyRuleToPastActions() {
      if (!this.reapplyRuleId) {
        return;
      }
      await this.$rest.rules.reapply_rule(this.reapplyRuleId);

      this.$notifier.success("Your request will be processed!");
      this.reapplyRuleId = null;
      this.reapplyRuleDialog = false;
    },
    async getRules() {
      const resp = await this.$rest.rules.get_collection({
        limit: this.currentPageSize,
        page: this.currentPageNumber,
      });
      const self = this;
      this.rules = resp.data.items.map(item => ({
        ...item,
        creative: (function (tactic, creativeId) {
          let tacticKey = '';

          if (tactic === 1) {
            tacticKey = 'emailCreatives';
          } else if (tactic === 2) {
            tacticKey = 'directMailCreatives';
          } else if (tactic === 5) {
            tacticKey = 'bannerCreatives';
          } else if (tactic === 7) {
            tacticKey = 'webCreatives';
          } else if (tactic === 8) {
            tacticKey = 'eventCreatives';
          } else if (tactic === 10) {
            tacticKey = 'formCreatives';
          } else if (tactic === 11) {
            tacticKey = 'alertCreatives';
          }

          if (!tacticKey) {
            return 'All Creatives';
          }

          const foundCreative = self[tacticKey].find(creative => creative.id == creativeId);
          if (!foundCreative) {
            return 'All Creatives';
          }

          if (tactic === 7) {
            let label = '';

            if (foundCreative.name) {
              label = foundCreative.name;

              if (foundCreative.url) {
                label += ' (' + foundCreative.url +')';
              }
            } else if (foundCreative.url) {
              label = foundCreative.url;
            } else {
              label = 'Unknown';
            }

            return label;
          }

          return foundCreative.name;
        })(parseInt(item.tacticType), item.creativeId),
      }));

      this.totalRules = resp.data.totalCount;
    },
    async getSegments() {
      const resp = await this.$rest.lead_segments.get_collection({
        ignorePagination: true,
        sort: ['minimumScore:asc', 'maximumScore:asc'],
      });

      this.segments = resp.data.items;
    },
    // Pagination
    async changePageSize(size) {
      this.currentPageSize = size;
      await this.getRules();
    },
    async getAvailableItemsNextPage() {
      this.currentPageNumber += 1;
      await this.getRules();
    },
    async get_available_items_prev_page() {
      this.currentPageNumber -= 1;
      await this.getRules();
    },
    async loadCreatives() {
      const params = {
        isArchived: false,
        fields: ['id', 'name'],
        ignorePagination: true,
      };
      const [
        {data: bannerCreatives},
        {data: eventCreatives},
        {data: webCreatives},
        {data: emailCreatives},
        {data: directMailCreatives},
        {data: alertCreatives},
        {data: formCreatives},
      ] = await Promise.all([
        this.$rest.banners.get_collection(params),
        this.$rest.events.get_collection(params),
        this.$rest.website.get_collection({
          params: {
            fields: ['id', 'name', 'url'],
            ignorePagination: true,
          },
        }),
        this.$rest.creative.email.get_collection(params),
        this.$rest.directmails.get_collection(params),
        this.$rest.alerts.get_collection(params),
        this.$rest.forms.get_collection(params),
      ]);

      this.$set(this, 'bannerCreatives', bannerCreatives.items);
      this.$set(this, 'eventCreatives', eventCreatives.items);
      this.$set(this, 'webCreatives', webCreatives.items);
      this.$set(this, 'emailCreatives', emailCreatives.items);
      this.$set(this, 'directMailCreatives', directMailCreatives.items);
      this.$set(this, 'alertCreatives', alertCreatives.items);
      this.$set(this, 'formCreatives', formCreatives.items);
    },
  },
};
</script>
