<template>
  <div v-if="actionCodes.length !== 0" class="search-table">
    <v-simple-table>
      <thead>
        <tr class="search-table__header">
          <th>
            <div class="ml-4">
              Action
            </div>
          </th>
          <th>Creative</th>
          <th>Score</th>
          <th>Times to Score</th>
          <th>Created</th>
          <th v-if="isPulseAdmin || isPulseUser" />
        </tr>
      </thead>
      <tbody class="search-table__content">
        <tr v-for="(item, index) in rules" :key="index">
          <td>
            <div class="ml-4" style="text-transform: capitalize">
              {{ getActionName(item.tacticType, item.actionCode) }}
            </div>
          </td>
          <td>{{ item.creative || "All Creatives" }}</td>
          <td>{{ item.score }}</td>
          <td>{{ getTimesToScoreText(item) }}</td>
          <td>
            <div :title="getDateWithDayNameAndTime(item.createdAt)">
              {{ getDate(item.createdAt) }}
            </div>
          </td>
          <td v-if="isPulseAdmin || isPulseUser">
            <v-menu
              v-if="listItems.length"
              nudge-top="-5px"
              :nudge-left="getMenuNudgeLeft"
              :offset-y="true"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  class="elevation-0 outlined-btn px-lg-10 px-xl-15 ml-5 ml-lg-10 ml-xl-20"
                  height="34px"
                  plain
                  v-bind="attrs"
                  v-on="on"
                >
                  Actions
                </v-btn>
              </template>
              <OuterList
                title="Actions"
                :items="listItems"
                @click="$emit('action', { event: $event, item })"
              />
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
import OuterList from "@/sharedComponents/OuterList";
import {mapGetters} from "vuex";

export default {
  name: "LeadScoreRulesTable",
  components: {
    OuterList,
  },
  mixins: [datesMixin],
  props: {
    rules: {
      default: () => [],
    },
  },
  data() {
    return {
      actionCodes: [],
      listItems: [
        {
          icon: 'mdi-pencil',
          label: "Edit",
          value: "edit",
        },
        {
          icon: 'mdi-arrow-u-right-bottom-bold',
          label: "Apply to Past Actions",
          value: "reapply",
        },
        {
          icon: 'mdi-delete-outline',
          label: "Delete",
          value: "delete",
        },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['isPulseAdmin', 'isPulseUser']),
    getMenuNudgeLeft() {
      if (this.$vuetify.breakpoint.mdAndDown) return "80px";
      if (this.$vuetify.breakpoint.lgOnly) return "32px";
      return "0";
    },
  },
  async created() {
    await this.getActionCodes();
  },
  methods: {
    getTimesToScoreText(item) {
      if (item.numActionsToScore && item.scoreTimeFrame) {
        return `${item.numActionsToScore} actions within ${item.numberOfUnits} ${item.unitOfTime}${item.numberOfUnits > 1 ? 's' : ''}`
      }
      else if (item.numActionsToScore) {
        return `${item.numActionsToScore} actions`
      }
      return 'No actions'
    },
    async getActionCodes() {
      this.actionCodes = (await this.$rest.rules.get_available_codes()).data;
    },
    getActionName(tacticType, actionCodeId) {
      const actionCode = this.actionCodes.find(i => i.id == actionCodeId && i.tacticId == tacticType);

      if (actionCode) {
        return actionCode.tacticName + ' - ' + actionCode.name
      }

      return 'Unknown'
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/contact-info-table.scss";
</style>
